import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    main: {
        marginTop: "6rem"
    }
});

export const AppPadding = () => {
    const classes = useStyles();

    return (<div className={classes.main} />);
}