import React from 'react';
import { GridContainer, GridItem, Button } from 'heatherstone-material-ui';
import { makeStyles } from "@material-ui/core/styles";

import landingStyle from "../../assets/jss/overrides/landingStyle";
import { withRouter, RouteChildrenProps } from 'react-router';
import SectionJoin from './Sections/SectionJoin';
import { SectionProduct } from './Sections/SectionProduct';

const useStyles = makeStyles(landingStyle);

export const Landing = withRouter((props: RouteChildrenProps) => {
    const classes = useStyles();
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <div className={classes.hero}>
                        <div className={classes.heroContent}>
                            <GridContainer justify="center">
                                <GridItem xs={8} sm={8} md={8} className={classes.heroBrandContainer}>
                                    <h1 className={classes.heroH1}>2020 AGCRA NEC Scholarships</h1>
                                    <h2 className={classes.heroText1}>
                                        The 2020 application deadline has been extended to <strong>June 30</strong>.
                                    </h2>
                                    <h2 className={classes.heroText2}>
                                        The 10th Annual AGCRA NEC Scholarships program seeks to award eight $1000 scholarships in July.
                                    </h2>
                                    <Button
                                        color="danger"
                                        size="lg"
                                        onClick={() => props.history.push(`/dashboard`)}
                                    >
                                        Apply now
                                    </Button>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <SectionProduct />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} className={classes.joinSection}>
                    <SectionJoin />
                </GridItem>
            </GridContainer>
        </div>

    );
})
