import axios from 'axios';
import { Dispatch } from 'redux';
import { iApplication } from '../../types/Types';
import { SET_APPLICATION_LOADING_TRUE, SET_APPLICATION_LOADING_FALSE, GET_APPLICATION } from '../types';

export const postApplication = async (token: any, data: iApplication) => {
    try {
        return await axios.post(`${process.env.REACT_APP_API_GATEWAY}/applications`, data, { cancelToken: token })
    } catch (err) { }
}

export const getApplication = async (dispatch: Dispatch<any>, id: string) => {
    dispatch(setLoadingTrue());
    return await axios.get(`${process.env.REACT_APP_API_GATEWAY}/applications/${id}`)
        .then(result => {
            dispatch(setApplication(result.data));
            dispatch(setLoadingFalse());

            return result.data;
        })
        .catch(err => {
            return err;
        });
}

export const updateApplication = async (token: any, data: iApplication) => {
    try {
        return await axios.put(`${process.env.REACT_APP_API_GATEWAY}/applications`, data, { cancelToken: token })
    } catch (err) { }
}

export const setApplication = (result: iApplication) => {
    return {
        type: GET_APPLICATION,
        payload: result
    }
}

export const setLoadingTrue = () => {
    return {
        type: SET_APPLICATION_LOADING_TRUE
    }
}

export const setLoadingFalse = () => {
    return {
        type: SET_APPLICATION_LOADING_FALSE
    }
}