import React from 'react';
import { Content } from './Content';
import { BrowserRouter as Router } from 'react-router-dom';
import { Footer } from 'heatherstone-material-ui';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    right: {
        float: "right",
        fontSize: ".8rem",
        color: "#bbb"
    },
    center: {
        textAlign: "center"
    }
});

const App = () => {
    const classes = useStyles();

    return (
        <Router>
            <Content />
            <Footer
                content={
                    <div>
                        <div className={classes.center}>
                            &copy; 2020 AGCRA
                        </div>
                        <div className={classes.right}>
                            v0.2.8, 06-05-20, 11:09am Pacific
                        </div>
                    </div>
                }
            />
        </Router>
    );
}

export default App;