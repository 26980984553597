import React from "react";
import { GridContainer, GridItem, Button } from 'heatherstone-material-ui';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import joinStyle from "../../../assets/jss/overrides/joinStyle";
import logo from "../../../assets/img/logo.png";

const useStyles = makeStyles(joinStyle);

export default function SectionProduct() {
	const classes = useStyles();
	return (
		<div>
			<GridContainer justify="center">
				<GridItem xs={12} sm={8} md={8}>
                <section className="creativeBlock overlay position-relative bgCover">
                            <div className={classes.header}>
                                <h4 className={classes.textWhite}>
                                    <span className={classes.logo}>
                                        <img src={logo} className="img-fluid" alt="..." />
                                    </span>
                                    <strong className={classes.textWhite + " " + classes.joinHeader}>AGCRA Membership</strong>
                                </h4>
                                <p className={classes.subText}>Preserving the proud heritage of the U.S. Army Adjutant General’s Corps</p>
                                <Button
                                    color="danger"
                                    size="lg"
                                    href="http://www.agcra.com"
                                >
                                learn more
                                </Button>
                            </div>
                </section>
				</GridItem>
			</GridContainer>
		</div>
	);
}
