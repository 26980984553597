import { SET_APPLICATION_LOADING_TRUE, SET_APPLICATION_LOADING_FALSE, GET_APPLICATION } from '../types';
import { initApplication } from '../../types/Types';

const initialState = {
    isLoading: true,
    data: initApplication
};

export default (state = initialState, action: { type: any, payload: any }) => {
    switch (action.type) {
        case GET_APPLICATION:
            return {
                ...state,
                data: action.payload
            }
        case SET_APPLICATION_LOADING_TRUE:
            return {
                ...state,
                isLoading: true
            };
        case SET_APPLICATION_LOADING_FALSE:
            return {
                ...state,
                isLoading: false
            };
        default:
            return state;
    }
}