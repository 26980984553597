import React from "react";
import { GridContainer, GridItem, Button } from 'heatherstone-material-ui';
import { withRouter, RouteChildrenProps } from 'react-router';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import landingStyle from "../../../assets/jss/overrides/landingStyle";

const useStyles = makeStyles(landingStyle);

export const SectionProduct = withRouter((props: RouteChildrenProps) => {
	const classes = useStyles();
	return (
		<div>
			<GridContainer justify="center">
				<GridItem xs={12} sm={12} md={8}>
					<p className={classes.introP}>
						Our scholarship program recognizes and rewards eligible AGCRA members and their dependents for excellence in education and community support.
						The AGCRA Scholarship Selection Board will review and evaluate applications each year.
          			</p>
				</GridItem>
				<GridItem xs={12} sm={12} md={8}>
					<h3 className={classes.sectionHead}>Eligibility</h3>
					<ul>
						<li>Any current AGCRA member or AGCRA member’s dependent can apply.</li>
						<li>Applicant must be enrolled as a full time student at an accredited 2 or 4 year college or university.</li>
						<li>Applicant must maintain a B average GPA or higher.</li>
						<li>Applicant must not have received any previous AGCRA scholarship (except for a second year extension).</li>
					</ul>
					<h3 className={classes.sectionHead}>How to apply</h3>
					<ul>
						<li>Proof of acceptance and / or admission to an accredited university or college. If final admission acceptance has not been received by the application deadline, a copy of the college / university application(s) submitted by the applicant will suffice. When this is the case, a selected applicant must provide proof of acceptance no later than 1 August.</li>
						<li>Letters of recommendation from High School Principal, College Dean, or School Counselor (must have at least one)</li>
						<li>Letters of recommendation from two of the three following individuals: high school teacher or coach, religious official, US City, county, state or national government official</li>
						<li>Most recent transcript from high school or college</li>
						<li>Personal letter from applicant giving reasons for AGCRA scholarship request, plans for the future, summary of school and other activities, personal interests, and any other information the applicant believes to be pertinent</li>
						<li>Head and shoulders photograph. Note – Photo will not be used during scholarship evaluation or selection; however, the photo will be used for AGCRA publicity in the event the applicant is selected</li>
						<li>Applicants must be an AGCRA member in good standing or the dependent of an AGCRA member in good standing. The member ID will need to be included with the application. (Just the number, not a membership card.)</li>
						<li>AGCRA Member’s Authorized Dependent Applicants Only: Copy of sponsor’s signed memorandum verifying that the applicant is their authorized dependent</li>
						<li>AGCRA scholarship recipients wishing to extend their scholarship for a second year must provide transcripts verifying a B average (or equivalent) or higher GPA during the first year covered by the first scholarship</li>
					</ul>
				</GridItem>
				<GridItem xs={12} sm={12} md={8}>
					<Button
						color="danger"
						size="lg"
						onClick={() => props.history.push(`/dashboard`)}
					>
						Apply now
                    </Button>
				</GridItem>
			</GridContainer>
		</div>
	);
})
