import S3 from 'aws-s3';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, createRef, useEffect } from 'react';
import { makeStyles, FormControlLabel, Checkbox, RadioGroup, Radio } from '@material-ui/core';
import { GridContainer, GridItem, CustomInput, CustomFileInput, Button, ImageUpload, SnackbarContent } from 'heatherstone-material-ui';
import { withRouter, RouteChildrenProps } from 'react-router';
import AttachFile from "@material-ui/icons/AttachFile";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import InputLabel from "@material-ui/core/InputLabel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faDotCircle } from "@fortawesome/free-solid-svg-icons";
import fieldsStyle from '../../assets/jss/overrides/fieldsStyle';
import { iApplication, iFileBoolean, initLoadingFile, StoreState } from '../../types/Types';
import { useAuth0 } from '../../utilities/react-auth0-spa';
import defaultImage from "../../assets/img/image_placeholder";
import defaultAvatar from "../../assets/img/placeholder";
import Check from "@material-ui/icons/Check";
import { postApplication, getApplication } from '../../system/actions/applicationActions';

const fileConfig = {
	bucketName: process.env.REACT_APP_BUCKET_NAME ? process.env.REACT_APP_BUCKET_NAME : "",
	region: process.env.REACT_APP_REGION ? process.env.REACT_APP_REGION : "",
	accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID ? process.env.REACT_APP_ACCESS_KEY_ID : "",
	secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY ? process.env.REACT_APP_SECRET_ACCESS_KEY : "",
	s3Url: process.env.REACT_APP_S3_URL ? process.env.REACT_APP_S3_URL : ""
}

const useStyles = makeStyles(fieldsStyle);

export const Dashboard = withRouter((props: RouteChildrenProps) => {
	const source = axios.CancelToken.source();
	const classes = useStyles();
	const dispatch = useDispatch();
	const S3Client = new S3(fileConfig);
	const { loading, user } = useAuth0();
	const userId = !loading && user && user.sub ? user.sub : "";

	const [avatar] = useState(false);

	const [isComplete, setIsComplete] = useState(false);
	const [isSubmitting, setSubmitting] = useState(false);
	const [isSubmitted, setSuccessOnSubmit] = useState(false);

	const [isFileLoading, setFileLoading] = useState<iFileBoolean>(initLoadingFile);

	const [isReadySubmit, setIsReadySubmit] = useState(false);

	let fileInput = createRef<any>();
	const [file, setFile] = useState<any>(null);
	const [imagePreviewUrl, setImagePreviewUrl] = useState(avatar ? defaultAvatar : defaultImage);

	const [isApplicationLoading, setApplicationLoading] = useState(useSelector((state: StoreState) => state.application.isLoading));
	const [application, setApplication] = useState<iApplication>(useSelector((state: StoreState) => state.application.data));

	const [isPersonalInfoComplete, setPersonalComplete] = useState(false);
	const [isAcceptanceComplete, setAcceptanceComplete] = useState(false);
	const [isTranscriptComplete, setTranscriptComplete] = useState(false);
	const [isRecommendationComplete, setRecommendationComplete] = useState(false);

	useEffect(() => {
		if (isApplicationLoading && !loading) {
			setApplicationLoading(false);
			getApplication(dispatch, userId)
				.then((res: { status: boolean, application: iApplication }) => {
					if (res.status) {
						setApplication(res.application)
						setFile(res.application.applicant.photo)
						if (res.application.applicant.photo !== "" && res.application.applicant.photo !== null) {
							setImagePreviewUrl(res.application.applicant.photo)
						} else {
							setImagePreviewUrl(avatar ? defaultAvatar : defaultImage)
						}
					}
				});
		}

		if (application.applicant.firstName !== "" && application.applicant.lastName !== "" && application.applicant.email !== "" && application.applicant.photo !== "" && application.applicant.phone !== "") {
			setPersonalComplete(true)
		} else { setPersonalComplete(false) }
		if (application.responses.acceptanceLetter.institution !== "" && application.responses.acceptanceLetter.url !== "") {
			setAcceptanceComplete(true)
		} else { setAcceptanceComplete(false) }
		if (application.responses.transcript.institution !== "" && application.responses.transcript.url !== "") {
			setTranscriptComplete(true)
		} else { setTranscriptComplete(false) }
		if (application.responses.recommendationLetters[0].url !== "" && application.responses.recommendationLetters[0].author !== "" && application.responses.recommendationLetters[0].source !== "") {
			setRecommendationComplete(true)
		} else { setRecommendationComplete(false) }
		if (application.responses.personalLetter !== "" && application.applicant.membershipId !== "" && isPersonalInfoComplete && isAcceptanceComplete && isTranscriptComplete && isRecommendationComplete) {
			setIsComplete(true)
		} else { setIsComplete(false) }
	}, [
		dispatch,
		avatar,
		userId,
		loading,
		application,
		isAcceptanceComplete,
		isApplicationLoading,
		isPersonalInfoComplete,
		isRecommendationComplete,
		isTranscriptComplete,
		source
	]);

	const handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files[0]) {
			const file = e.target.files[0];
			const fileName = e.target.files[0].name;
			const id = e.target.id.split("file-")[1];

			setFileLoading((prevState: iFileBoolean) => ({
				...prevState,
				[id]: true
			}));

			S3Client
				.uploadFile(file, `files/${userId}/pdf/` + fileName.split(".")[0])
				.then((data: { location: string }) => {
					let appData = application;
					appData.id = userId;
					appData.status = "pending";

					if (id === "acceptance") {
						appData.responses.acceptanceLetter.url = data.location;
					} else if (id === "memorandum") {
						appData.responses.dependentAuthorization.url = data.location;
					} else if (id === "transcript") {
						appData.responses.transcript.url = data.location;
					} else if (id === "letter1" || id === "letter2" || id === "letter3") {
						const index = parseInt(id.split("letter")[1]) - 1;
						let lettersCopy = application.responses.recommendationLetters;
						lettersCopy[index].url = data.location;
						appData.responses.recommendationLetters = lettersCopy;
					}
					setApplication(appData)

					setFileLoading(prevState => ({
						...prevState,
						[id]: false
					}));

					postApplication(source.token, appData);
				})
				.catch(() => { });
		}
	}

	const handleClick = () => {
		fileInput.current.click();
	};

	const handleRemove = () => {
		setFile(null);
		setImagePreviewUrl(avatar ? defaultAvatar : defaultImage);
		setApplication(prevState => ({
			...prevState,
			applicant: {
				...prevState.applicant,
				photo: ""
			}
		}));
		fileInput.current.value = null;
	};

	const handleFileRemove = (id: string) => {
		let removeData = application;
		removeData.id = userId;
		removeData.status = "pending";

		if (id === "acceptance") {
			removeData.responses.acceptanceLetter.url = "";
			setApplication(prevState => ({
				...prevState,
				responses: {
					...prevState.responses,
					acceptanceLetter: {
						...prevState.responses.acceptanceLetter,
						url: ""
					}
				}
			}))
		} else if (id === "memorandum") {
			removeData.responses.dependentAuthorization.url = "";
			setApplication(prevState => ({
				...prevState,
				responses: {
					...prevState.responses,
					dependentAuthorization: {
						...prevState.responses.dependentAuthorization,
						url: ""
					}
				}
			}))
		} else if (id === "transcript") {
			removeData.responses.transcript.url = "";
			setApplication(prevState => ({
				...prevState,
				responses: {
					...prevState.responses,
					transcript: {
						...prevState.responses.transcript,
						url: ""
					}
				}
			}))
		} else if (id === "letter1" || id === "letter2" || id === "letter3") {
			const index = parseInt(id.split("letter")[1]) - 1;
			let lettersCopy = application.responses.recommendationLetters;
			lettersCopy[index].url = "";
			removeData.responses.recommendationLetters[index].url = "";
			setApplication(prevState => ({
				...prevState,
				responses: {
					...prevState.responses,
					recommendationLetters: lettersCopy
				}
			}))
		}

		postApplication(source.token, removeData);
	};

	const handleUploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files[0]) {
			const file = e.target.files[0];
			const fileName = uuidv4().toString();
			S3Client
				.uploadFile(file, `images/original/` + fileName)
				.then(async (data: { key: string, location: string }) => {
					let appData = application;
					appData.id = userId;
					appData.status = "pending";
					appData.applicant.photo = data.location.replace("original", "resized-images")
					setImagePreviewUrl(data.location);
					setApplication(appData)
					setFile(file);

					postApplication(source.token, appData);
				})
				.catch((err: {}) => console.error(err));
		}
	}

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const id = e.target.id;
		const name = e.target.name

		if (id === "firstName" || id === "lastName" || id === "email" || id === "phone" || id === "membershipId") {
			setApplication(prevState => ({
				...prevState,
				applicant: {
					...prevState.applicant,
					[e.target.id]: e.target.value
				}
			}))
		} else if (id === "transcriptInstitution") {
			setApplication(prevState => ({
				...prevState,
				responses: {
					...prevState.responses,
					transcript: {
						...prevState.responses.transcript,
						institution: e.target.value
					}
				}
			}))
		} else if (id === "acceptanceInstitution") {
			setApplication(prevState => ({
				...prevState,
				responses: {
					...prevState.responses,
					acceptanceLetter: {
						...prevState.responses.acceptanceLetter,
						institution: e.target.value
					}
				}
			}))
		} else if (id === "personalLetter") {
			setApplication(prevState => ({
				...prevState,
				responses: {
					...prevState.responses,
					[e.target.id]: e.target.value
				}
			}))
		} else if (id === "firstAuthor" || id === "secondAuthor" || id === "thirdAuthor") {
			let index = 0;
			const splitString: any = id.split("Author");
			if (splitString[0] === "first") index = 0
			else if (splitString[0] === "second") index = 1
			else if (splitString[0] === "third") index = 2

			let lettersCopy = application.responses.recommendationLetters;
			lettersCopy[index].author = e.target.value;
			setApplication(prevState => ({
				...prevState,
				responses: {
					...prevState.responses,
					recommendationLetters: lettersCopy
				}
			}))
		} else if (id === "firstSource" || id === "secondSource" || id === "thirdSource") {
			let index = 0;
			const splitString: any = id.split("Source");
			if (splitString[0] === "first") index = 0
			else if (splitString[0] === "second") index = 1
			else if (splitString[0] === "third") index = 2

			let lettersCopy = application.responses.recommendationLetters;
			lettersCopy[index].source = e.target.value;
			setApplication(prevState => ({
				...prevState,
				responses: {
					...prevState.responses,
					recommendationLetters: lettersCopy
				}
			}))
		}

		if (name === "acceptType") {
			setApplication(prevState => ({
				...prevState,
				responses: {
					...prevState.responses,
					acceptanceLetter: {
						...prevState.responses.acceptanceLetter,
						status: e.target.value === "applied" || e.target.value === "accepted" ? e.target.value : "applied"
					}
				}
			}))
		}
	}

	const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const data = application;
		data.id = userId;
		data.status = "submitted";

		window.scrollTo(0, 0);
		setSubmitting(true);

		postApplication(source.token, data)
			.then(() => {
				setSubmitting(false);
				setSuccessOnSubmit(true);
			});
	}

	const handleOnBlur = () => {
		let appData = application;
		appData.id = userId;
		appData.status = "pending";
		postApplication(source.token, appData);
	}

	if (loading || isApplicationLoading) return (<h1>Loading...</h1>);

	return (<>
		<div className={classes.section}>
			<div className={classes.container}>
				<div>
					<GridContainer>
						<GridItem md={12} sm={12}>
							<p>
								Known bug on Mac/Safari, please use a different browser.
							</p>
							{isSubmitting
								? (<SnackbarContent
									message={
										<span>
											<b>INFO!</b> Your form is submitting...
          						</span>
									}
									color="info"
									icon={Check}
								/>)
								: isSubmitted
									? (<SnackbarContent
										message={
											<span>
												<b>SUCCESS!</b> Your form has been submitted successfully.
          									</span>
										}
										close
										color="success"
										icon={Check}
									/>)
									: (null)}
							{application.status === "submitted"
								? (<SnackbarContent
									message={
										<span>
											<b>SUBMITTED</b> You already have submitted your form...
          								</span>
									}
									color="success"
									icon={Check}
								/>)
								: null}
						</GridItem>
					</GridContainer>
					<form onSubmit={handleSubmitForm} className={classes.form}>
						<GridContainer>
							<GridItem md={8} sm={8}>
								<GridContainer>
									<GridItem className={classes.formAreas + " " + classes.mainRaised}>
										<GridContainer>
											<GridItem>
												<h3 className={classes.title}>Applicant</h3>
											</GridItem>
											<GridItem md={4} sm={4} xs={12} className={classes.profileImage}>
												<ImageUpload
													accept="image/*"
													className={classes.imagePreview}
													file={file}
													fileInput={fileInput}
													imagePreviewUrl={imagePreviewUrl}
													handleClick={handleClick}
													handleRemove={handleRemove}
													onChange={handleUploadImage}
													addButtonProps={{ round: true, color: "danger", disabled: application.status === "submitted" }}
													changeButtonProps={{ round: true, color: "danger", disabled: application.status === "submitted" }}
													removeButtonProps={{ round: true, color: "danger", disabled: application.status === "submitted" }}
												/>
												<p className={classes.p}>Head and shoulders photograph. Photo will not be used during scholarship selection.</p>
											</GridItem>
											<GridItem md={8} sm={8} xs={12}>
												<CustomInput
													labelText="First name"
													id="firstName"
													formControlProps={{
														fullWidth: true
													}}
													inputProps={{
														color: "secondary",
														value: application.applicant.firstName,
														onChange: handleOnChange,
														onBlur: handleOnBlur,
														disabled: application.status === "submitted"
													}}
												/>
												<CustomInput
													labelText="Last name"
													id="lastName"
													formControlProps={{
														fullWidth: true
													}}
													inputProps={{
														color: "secondary",
														value: application.applicant.lastName,
														onChange: handleOnChange,
														onBlur: handleOnBlur,
														disabled: application.status === "submitted"
													}}
												/>
												<CustomInput
													labelText="Email"
													id="email"
													formControlProps={{
														fullWidth: true
													}}
													inputProps={{
														type: "email",
														color: "secondary",
														value: application.applicant.email,
														onChange: handleOnChange,
														onBlur: handleOnBlur,
														disabled: application.status === "submitted"
													}}
												/>
												<CustomInput
													labelText="Phone"
													id="phone"
													formControlProps={{
														fullWidth: true
													}}
													inputProps={{
														color: "secondary",
														value: application.applicant.phone,
														onChange: handleOnChange,
														onBlur: handleOnBlur,
														disabled: application.status === "submitted"
													}}
												/>
												<FormControlLabel
													disabled={application.status === "submitted"}
													control={
														<Checkbox
															checked={application.applicant.extension}
															tabIndex={-1}
															onClick={() => setApplication(prevState => ({
																...prevState,
																applicant: {
																	...prevState.applicant,
																	extension: !prevState.applicant.extension
																}
															}))}
															checkedIcon={<Check className={classes.checkedIcon} />}
															icon={<Check className={classes.uncheckedIcon} />}
															classes={{
																checked: classes.checked,
																root: classes.checkRoot
															}}
														/>
													}
													classes={{ label: classes.label, root: classes.labelRoot }}
													label="I am applying to extend my scholarship for a second year"
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
									<GridItem className={classes.formAreas + " " + classes.mainRaised}>
										<h3 className={classes.title}>AGCRA Membership</h3>
										<p>All applicants must submit a member ID. Dependent Applicants Only: Copy of sponsor’s signed letter verifying that the applicant is their authorized dependent.</p>
										<CustomInput
											labelText="AGCRA member ID"
											id="membershipId"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												color: "secondary",
												value: application.applicant.membershipId,
												onChange: handleOnChange,
												onBlur: handleOnBlur,
												disabled: application.status === "submitted"
											}}
										/>

										<FormControlLabel
											control={
												<Checkbox
													checked={application.responses.dependentAuthorization.isDependent}
													tabIndex={-1}
													onClick={() => {
														setApplication(prevState => ({
															...prevState,
															responses: {
																...prevState.responses,
																dependentAuthorization: {
																	...prevState.responses.dependentAuthorization,
																	isDependent: !prevState.responses.dependentAuthorization.isDependent
																}
															}
														}))

														handleOnBlur()
													}}
													checkedIcon={<Check className={classes.checkedIcon} />}
													icon={<Check className={classes.uncheckedIcon} />}
													classes={{
														checked: classes.checked,
														root: classes.checkRoot
													}}
												/>
											}
											classes={{ label: classes.label, root: classes.labelRoot }}
											label="I am a dependent of the member"
										/>
										{application.responses.dependentAuthorization.isDependent && <div>
											{(application.responses.dependentAuthorization.url === "" || application.responses.dependentAuthorization.url === null) && !isFileLoading.memorandum
												? (<CustomFileInput
													accept="application/pdf"
													onChange={handleUploadFile}
													onBlur={handleOnBlur}
													formControlProps={{
														fullWidth: true
													}}
													id="memorandum"
													labelText="Letter from member that applicant is an authorized dependent"
													labelProps={{
														className: classes.filePlaceholder
													}}
													inputProps={{
														color: "secondary",
														value: application.responses.dependentAuthorization.url,
														className: classes.fileField
													}}
													endButton={{
														buttonProps: {
															round: true,
															color: "danger",
															justIcon: true,
															fileButton: true
														},
														icon: <AttachFile />
													}}
												/>)
												: (<div className={classes.removeFileContainer}>
													<Button
														round
														disabled={isFileLoading.memorandum || application.status === "submitted"}
														color={isFileLoading.memorandum ? "success" : "danger"}
														onClick={() => handleFileRemove("memorandum")}>
														{isFileLoading.memorandum ? "Uploading" : "Remove"}
													</Button>
													{isFileLoading.memorandum ? null : (<><span> Filename: </span> {application.responses.dependentAuthorization.url ? application.responses.dependentAuthorization.url.split("/")[application.responses.dependentAuthorization.url.split("/").length - 1] : ""}</>)}
												</div>)}
										</div>}
									</GridItem>
									<GridItem className={classes.formAreas + " " + classes.mainRaised}>
										<h3 className={classes.title}>Acceptance Letter</h3>
										<p>If final admission acceptance has not been received by application deadline, a copy of the college / university application(s) submitted by the applicant will suffice.</p>
										<RadioGroup
											id="acceptType"
											aria-label="acceptType"
											name="acceptType"
											value={application.responses.acceptanceLetter.status}
											className={classes.radioGroup}
											onChange={handleOnChange}
											onBlur={handleOnBlur}
										>
											<FormControlLabel value="applied" control={<Radio />} label="Application" />
											<FormControlLabel value="accepted" control={<Radio />} label="Acceptance Letter" />
										</RadioGroup>
										<CustomInput
											labelText="Institution"
											id="acceptanceInstitution"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												color: "secondary",
												value: application.responses.acceptanceLetter.institution,
												onChange: handleOnChange,
												onBlur: handleOnBlur,
												disabled: application.status === "submitted"
											}}
										/>
										{(application.responses.acceptanceLetter.url === "" || application.responses.acceptanceLetter.url === null) && !isFileLoading.acceptance
											? (<CustomFileInput
												id="acceptance"
												onChange={handleUploadFile}
												onBlur={handleOnBlur}
												accept="application/pdf"
												formControlProps={{
													fullWidth: true
												}}
												labelText="Select PDF File..."
												labelProps={{
													className: classes.filePlaceholder
												}}
												inputProps={{
													color: "secondary",
													value: application.responses.acceptanceLetter.url,
													className: classes.fileField
												}}
												endButton={{
													buttonProps: {
														round: true,
														color: "danger",
														justIcon: true,
														fileButton: true
													},
													icon: <AttachFile />
												}}
											/>)
											: (<div className={classes.removeFileContainer}>
												<Button
													round
													disabled={isFileLoading.acceptance || application.status === "submitted"}
													color={isFileLoading.acceptance ? "success" : "danger"}
													onClick={() => handleFileRemove("acceptance")}>
													{isFileLoading.acceptance ? "Uploading" : "Remove"}
												</Button>
												{isFileLoading.acceptance ? null : (<><span> Filename: </span> {application.responses.acceptanceLetter.url ? application.responses.acceptanceLetter.url.split("/")[application.responses.acceptanceLetter.url.split("/").length - 1] : ""}</>)}
											</div>)}
									</GridItem>
									<GridItem className={classes.formAreas + " " + classes.mainRaised}>
										<h3 className={classes.title}>Recommendation Letters</h3>
										<p>Letters of recommendation can be from three of the following: high school teacher, coach, or principal; college dean; school counselor; religious official; US City, county, state or national government official.</p>
										<InputLabel className={classes.inputLabel}>
											First recommendation letter
												</InputLabel>
										<CustomInput
											labelText="Author"
											id="firstAuthor"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												color: "secondary",
												value: application.responses.recommendationLetters[0].author,
												onChange: handleOnChange,
												onBlur: handleOnBlur,
												disabled: application.status === "submitted"
											}}
										/>
										<CustomInput
											labelText="Author's job position"
											id="firstSource"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												color: "secondary",
												value: application.responses.recommendationLetters[0].source,
												onChange: handleOnChange,
												onBlur: handleOnBlur,
												disabled: application.status === "submitted"
											}}
										/>
										{(application.responses.recommendationLetters[0].url === "" || application.responses.recommendationLetters[0].url === null) && !isFileLoading.letter1
											? (<CustomFileInput
												accept="application/pdf"
												onChange={handleUploadFile}
												onBlur={handleOnBlur}
												formControlProps={{
													fullWidth: true
												}}
												labelText="Select PDF File..."
												labelProps={{
													className: classes.filePlaceholder
												}}
												id="letter1"
												inputProps={{
													color: "secondary",
													value: application.responses.recommendationLetters[0].url,
													className: classes.fileField
												}}
												endButton={{
													buttonProps: {
														round: true,
														color: "danger",
														justIcon: true,
														fileButton: true
													},
													icon: <AttachFile />
												}}
											/>)
											: (<div className={classes.removeFileContainer}>
												<Button
													round
													disabled={isFileLoading.letter1 || application.status === "submitted"}
													color={isFileLoading.letter1 ? "success" : "danger"}
													onClick={() => handleFileRemove("letter1")}>
													{isFileLoading.letter1 ? "Uploading" : "Remove"}
												</Button>
												{isFileLoading.letter1 ? null : (<><span> Filename: </span> {application.responses.recommendationLetters[0].url ? application.responses.recommendationLetters[0].url.split("/")[application.responses.recommendationLetters[0].url.split("/").length - 1] : ""}</>)}
											</div>)}
										<InputLabel className={classes.inputLabel}>
											Second recommendation letter
												</InputLabel>
										<CustomInput
											labelText="Author"
											id="secondAuthor"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												color: "secondary",
												value: application.responses.recommendationLetters[1].author,
												onChange: handleOnChange,
												onBlur: handleOnBlur,
												disabled: application.status === "submitted"
											}}
										/>
										<CustomInput
											labelText="Author's job position"
											id="secondSource"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												color: "secondary",
												value: application.responses.recommendationLetters[1].source,
												onChange: handleOnChange,
												onBlur: handleOnBlur,
												disabled: application.status === "submitted"
											}}
										/>
										{(application.responses.recommendationLetters[1].url === "" || application.responses.recommendationLetters[1].url === null) && !isFileLoading.letter2
											? (<CustomFileInput
												accept="application/pdf"
												onChange={handleUploadFile}
												onBlur={handleOnBlur}
												formControlProps={{
													fullWidth: true
												}}
												id="letter2"
												labelText="Select PDF File..."
												labelProps={{
													className: classes.filePlaceholder
												}}
												inputProps={{
													color: "secondary",
													value: application.responses.recommendationLetters[1].url,
													className: classes.fileField
												}}
												endButton={{
													buttonProps: {
														round: true,
														color: "danger",
														justIcon: true,
														fileButton: true
													},
													icon: <AttachFile />
												}}
											/>)
											: (<div className={classes.removeFileContainer}>
												<Button
													round
													disabled={isFileLoading.letter2 || application.status === "submitted"}
													color={isFileLoading.letter2 ? "success" : "danger"}
													onClick={() => handleFileRemove("letter2")}>
													{isFileLoading.letter2 ? "Uploading" : "Remove"}
												</Button>
												{isFileLoading.letter2 ? null : (<><span> Filename: </span> {application.responses.recommendationLetters[1].url ? application.responses.recommendationLetters[1].url.split("/")[application.responses.recommendationLetters[1].url.split("/").length - 1] : ""}</>)}
											</div>)}
										<InputLabel className={classes.inputLabel}>
											Third recommendation letter
												</InputLabel>
										<CustomInput
											labelText="Author"
											id="thirdAuthor"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												color: "secondary",
												value: application.responses.recommendationLetters[2].author,
												onChange: handleOnChange,
												onBlur: handleOnBlur,
												disabled: application.status === "submitted"
											}}
										/>
										<CustomInput
											labelText="Author's job position"
											id="thirdSource"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												color: "secondary",
												value: application.responses.recommendationLetters[2].source,
												onChange: handleOnChange,
												onBlur: handleOnBlur,
												disabled: application.status === "submitted"
											}}
										/>
										{(application.responses.recommendationLetters[2].url === "" || application.responses.recommendationLetters[2].url === null) && !isFileLoading.letter3
											? (<CustomFileInput
												accept="application/pdf"
												onChange={handleUploadFile}
												onBlur={handleOnBlur}
												formControlProps={{
													fullWidth: true
												}}
												id="letter3"
												labelText="Select PDF File..."
												labelProps={{
													className: classes.filePlaceholder
												}}
												inputProps={{
													color: "secondary",
													value: application.responses.recommendationLetters[2].url,
													className: classes.fileField
												}}
												endButton={{
													buttonProps: {
														round: true,
														color: "danger",
														justIcon: true,
														fileButton: true
													},
													icon: <AttachFile />
												}}
											/>)
											: (<div className={classes.removeFileContainer}>
												<Button
													round
													disabled={isFileLoading.letter3 || application.status === "submitted"}
													color={isFileLoading.letter3 ? "success" : "danger"}
													onClick={() => handleFileRemove("letter3")}>
													{isFileLoading.letter3 ? "Uploading" : "Remove"}
												</Button>
												{isFileLoading.letter3 ? null : (<><span> Filename: </span> {application.responses.recommendationLetters[2].url ? application.responses.recommendationLetters[2].url.split("/")[application.responses.recommendationLetters[2].url.split("/").length - 1] : ""}</>)}
											</div>)}
									</GridItem>
									<GridItem className={classes.formAreas + " " + classes.mainRaised}>
										<h3 className={classes.title}>Transcript</h3>
										<CustomInput
											labelText="Institution"
											id="transcriptInstitution"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												color: "secondary",
												value: application.responses.transcript.institution,
												onChange: handleOnChange,
												onBlur: handleOnBlur,
												disabled: application.status === "submitted"
											}}
										/>
										{(application.responses.transcript.url === "" || application.responses.transcript.url === null) && !isFileLoading.transcript
											? (<CustomFileInput
												accept="application/pdf"
												onChange={handleUploadFile}
												onBlur={handleOnBlur}
												formControlProps={{
													fullWidth: true
												}}
												id="transcript"
												labelText="Upload transcript as PDF..."
												labelProps={{
													className: classes.filePlaceholder
												}}
												inputProps={{
													color: "secondary",
													value: application.responses.transcript.url,
													className: classes.fileField
												}}
												endButton={{
													buttonProps: {
														round: true,
														color: "danger",
														justIcon: true,
														fileButton: true
													},
													icon: <AttachFile />
												}}
											/>)
											: (<div className={classes.removeFileContainer}>
												<Button
													round
													disabled={isFileLoading.transcript || application.status === "submitted"}
													color={isFileLoading.transcript ? "success" : "danger"}
													onClick={() => handleFileRemove("transcript")}>
													{isFileLoading.transcript ? "Uploading" : "Remove"}
												</Button>
												{isFileLoading.transcript ? null : (<><span> Filename: </span> {application.responses.transcript.url ? application.responses.transcript.url.split("/")[application.responses.transcript.url.split("/").length - 1] : ""}</>)}
											</div>)}
									</GridItem>
									<GridItem className={classes.formAreas + " " + classes.mainRaised}>
										<h3 className={classes.title}>Personal Statement</h3>
										<p>Personal letter from applicant giving reasons for AGCRA scholarship request, plans for the future, summary of school and other activities, personal interests, and any other information the applicant believes to be pertinent. The statement can be as long as you wish.</p>
										<CustomInput
											labelText="Personal statement..."
											id="personalLetter"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												multiline: true,
												rows: 5,
												color: "secondary",
												value: application.responses.personalLetter,
												onChange: handleOnChange,
												onBlur: handleOnBlur,
												disabled: application.status === "submitted"
											}}
										/>
									</GridItem>
								</GridContainer>
							</GridItem>
							<GridItem md={4} sm={4}>
								<div className={classes.mainRaised + " " + classes.progressBar}>
									<h3 className={classes.title}>Progress</h3>
									<p className={classes.saveNotice}>If you do not complete your application in one session, your entered information is still saved automatically</p>
									<List className={classes.progress}>
										<ListItem
											className={isPersonalInfoComplete ? (classes.listDone) : ""}
										>
											{isPersonalInfoComplete ? (<span className={classes.faIcon}><FontAwesomeIcon icon={faCheckCircle} /></span>) : (<span className={classes.faIcon}><FontAwesomeIcon icon={faDotCircle} /></span>)}Personal information
                                    	</ListItem>
										<ListItem
											className={application.applicant.membershipId !== "" ? (classes.listDone) : ""}
										>
											{application.applicant.membershipId !== "" ? (<span className={classes.faIcon}><FontAwesomeIcon icon={faCheckCircle} /></span>) : (<span className={classes.faIcon}><FontAwesomeIcon icon={faDotCircle} /></span>)}AGCRA membership
										</ListItem>
										<ListItem
											className={isAcceptanceComplete ? (classes.listDone) : ""}
										>
											{isAcceptanceComplete ? (<span className={classes.faIcon}><FontAwesomeIcon icon={faCheckCircle} /></span>) : (<span className={classes.faIcon}><FontAwesomeIcon icon={faDotCircle} /></span>)}Acceptance letter
										</ListItem>
										<ListItem
											className={isRecommendationComplete ? (classes.listDone) : ""}
										>
											{isRecommendationComplete ? (<span className={classes.faIcon}><FontAwesomeIcon icon={faCheckCircle} /></span>) : (<span className={classes.faIcon}><FontAwesomeIcon icon={faDotCircle} /></span>)}Recommendation letters
										</ListItem>
										<ListItem
											className={isTranscriptComplete ? (classes.listDone) : ""}
										>
											{isTranscriptComplete ? (<span className={classes.faIcon}><FontAwesomeIcon icon={faCheckCircle} /></span>) : (<span className={classes.faIcon}><FontAwesomeIcon icon={faDotCircle} /></span>)}Transcript
										</ListItem>
										<ListItem
											className={application.responses.personalLetter !== "" ? (classes.listDone) : ""}
										>
											{application.responses.personalLetter !== "" ? (<span className={classes.faIcon}><FontAwesomeIcon icon={faCheckCircle} /></span>) : (<span className={classes.faIcon}><FontAwesomeIcon icon={faDotCircle} /></span>)}Personal statement
										</ListItem>
									</List>
									{isComplete && application.status !== "submitted"
										? (<FormControlLabel
											control={
												<Checkbox
													tabIndex={-1}
													onClick={() => setIsReadySubmit(!isReadySubmit)}
													checkedIcon={<Check className={classes.checkedIcon} />}
													icon={<Check className={classes.uncheckedIcon} />}
													classes={{
														checked: classes.checked,
														root: classes.checkRoot
													}}
												/>
											}
											classes={{ label: classes.label, root: classes.labelRoot }}
											label="I am ready to submit my application"
										/>)
										: null}
									{application.status === "pending"
										? (<Button
											color="danger"
											type="submit"
											className={classes.buttonSubmit}
											disabled={(!isComplete && !isReadySubmit) || (isComplete && !isReadySubmit) || (!isComplete && isReadySubmit)}
										>
											Submit
										</Button>)
										: null}
								</div>
							</GridItem>
						</GridContainer>
					</form>
				</div>
			</div>
		</div>
	</>);
})
