import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from "./react-auth0-spa";
import { AppHeader } from "../interface/structure/AppHeader";
import { AppPadding } from "../interface/structure/AppPadding";

const PrivateRoute = ({ component: Component, path, ...rest }: any) => {
    const { loading, isAuthenticated, loginWithRedirect, getIdTokenClaims, user } = useAuth0();

    useEffect(() => {
        if (loading || isAuthenticated) return;
        const fn = async () => {
            await loginWithRedirect({
                appState: { targetUrl: path }
            });
        };

        fn();
    }, [loading, isAuthenticated, loginWithRedirect, path, getIdTokenClaims, user]);

    const render = (props: any) => {
        return (isAuthenticated === true) ? (<>
            <AppHeader />
            <AppPadding />
            <Component {...props} />
        </>) : null;
    }

    return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;