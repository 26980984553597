import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from './utilities/react-auth0-spa';
import history from "./utilities/history";
import App from './interface/structure/App';
import './assets/css/material-kit-pro-react.css';
import { Provider } from 'react-redux';
import store from './system/store';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

//THEME
const theme = createMuiTheme({
	palette: {
		primary: {
			light: '#de4039',
			main: '#de4039',
			dark: '#de4039',
			contrastText: '#fff',
		},
		secondary: {
			light: '#1e73be',
			main: '#1e73be',
			dark: '#1e73be',
			contrastText: '#000',
		}
	},
});

const onRedirectCallback = (appState: any) => {
	history.push(
		appState && appState.targetUrl
			? appState.targetUrl
			: window.location.pathname
	);
};

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN ? process.env.REACT_APP_AUTH0_DOMAIN : "";
const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID ? process.env.REACT_APP_AUTH0_CLIENT_ID : "";

ReactDOM.render(
	<Auth0Provider
		domain={auth0Domain}
		client_id={auth0ClientId}
		redirect_uri={`${window.location.origin}/dashboard`}
		onRedirectCallback={onRedirectCallback}
	>
		<Provider store={store}>
			<MuiThemeProvider theme={theme}>
				<App />
			</MuiThemeProvider>
		</Provider>
	</Auth0Provider >
	, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
