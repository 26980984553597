export type StoreState = {
    application: {
        isLoading: boolean,
        data: iApplication
    }
}

export const initFileNameValues = {
    acceptance: "",
    letter1: "",
    letter2: "",
    letter3: "",
    transcript: "",
    personalLetter: "",
    agcraMemberId: "",
    memorandum: "",
    proof: ""
}

export const initApplication: iApplication = {
    id: "",
    type: "application",
    status: "pending",
    applicant: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        photo: "",
        membershipId: "",
        extension: false
    },
    responses: {
        acceptanceLetter: {
            institution: "",
            status: "applied",
            url: ""
        },
        recommendationLetters: [
            {
                source: "",
                author: "",
                url: ""
            },
            {
                source: "",
                author: "",
                url: ""
            },
            {
                source: "",
                author: "",
                url: ""
            }
        ],
        transcript: {
            institution: "",
            url: ""
        },
        personalLetter: "",
        dependentAuthorization: {
            isDependent: false,
            url: ""
        }
    }
}

export const initLoadingFile = {
    acceptance: false,
    letter1: false,
    letter2: false,
    letter3: false,
    transcript: false,
    personalLetter: false,
    agcraMemberId: false,
    memorandum: false,
    proof: false
}

export interface iFile {
    acceptance: string;
    letter1: string;
    letter2: string;
    letter3: string;
    transcript: string;
    personalLetter: string;
    agcraMemberId: string;
    memorandum: string;
    proof: string;
}

export interface iFileBoolean {
    acceptance: boolean;
    letter1: boolean;
    letter2: boolean;
    letter3: boolean;
    transcript: boolean;
    personalLetter: boolean;
    agcraMemberId: boolean;
    memorandum: boolean;
    proof: boolean;
}

export interface iApplication {
    id: string;
    type: "application";
    status: "submitted" | "pending"
    applicant: {
        firstName: string;
        lastName: string;
        email: string;
        phone: string;
        photo: string;
        membershipId: string;
        extension: boolean;
    },
    responses: {
        acceptanceLetter: {
            institution: string;
            status: "applied" | "accepted";
            url: string;
        },
        recommendationLetters: {
            source: string;
            author: string;
            url: string;
        }[],
        transcript: {
            institution: string;
            url: string;
        },
        personalLetter: string;
        dependentAuthorization: {
            isDependent: boolean;
            url: string;
        }
    }
}