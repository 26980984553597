import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth0 } from "./react-auth0-spa";

const PublicRoute = ({ component: Component, path, ...rest }: any) => {
    const { loading, isAuthenticated } = useAuth0();

    useEffect(() => {
        if (loading || isAuthenticated) return;
    }, [isAuthenticated, loading]);

    if (isAuthenticated === true) { return <Redirect exact to="/dashboard" /> }

    const render = (props: any) => (<Component {...props} />);

    return <Route path={path} render={render} {...rest} />;
};

export default PublicRoute;