import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { RouteChildrenProps, withRouter } from 'react-router';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/List";
import { Header, Button } from "heatherstone-material-ui";
import headerLinksStyle from "../../assets/jss/overrides/headerLinkStyle";
import { useAuth0 } from "../../utilities/react-auth0-spa";

const useStyles = makeStyles(headerLinksStyle);

export const AppHeader = withRouter((props: RouteChildrenProps) => {
    const { logout } = useAuth0();
    const classes = useStyles();

    return (
        <Header
            brand={<h1 className={classes.branding}>AGCRA SCHOLARSHIPS</h1>}
            color="dark"
            fixed
            to="/dashboard"
            links={<>
                <List className={classes.list + " " + classes.mlAuto}>
                    <ListItem className={classes.listItem}>
                        <Button
                            color="transparent"
                            className={classes.navLink}
                            onClick={() => process.env.NODE_ENV === "development" ? logout({ returnTo: "http://localhost:3000" }) : logout()}
                        >
                            Logout
                        </Button>
                    </ListItem>
                </List>
            </>}
        />
    );
})
