import { createStyles } from "@material-ui/core";
import { main, section, container, mainRaised, title, hexToRgb, blackColor, grayColor, dangerColor } from "../material-kit-pro-react";

const fieldsStyle = () => createStyles({
    main: {
        ...main,
        padding: "40px"
    },
    mainRaised,
    section: {
        ...section,
        padding: "70px 0px"
    },
    title: {
        ...title,
        marginBottom: 0,
        fontFamily: '"Bungee", "Times New Roman", serif',
        fontWeight: "normal",
    },
    container: {
        ...container,
        zIndex: 2
    },
    pullRight: {
        float: "right"
    },
    mainPrice: {
        margin: "10px 0px 25px"
    },
    stripe: {
        paddingTop: "27px",
        paddingLeft: "25px",
        paddingRight: "25px",
        minHeight: "1px",
        position: "relative",
        width: "100%",
        margin: "0 0 17px 0"

    },
    form: {
        marginBottom: "-70px"
    },
    progress: {
        "&>li": {
            paddingLeft: 0
        }
    },
    profileImage: {
        paddingTop: "12px",
        "&>div>div>span>button": {
            maxWidth: "45%",
            marginRight: "2.5%"
        },
        "&>.fileinput>.thumbnail>img": {
            width: "600",
        },
        "&>.fileinput": {
            textAlign: "left",
        }
    },
    listDone: {
        paddingLeft: "0",
        color: "#059803",
        "&>span": {
            color: "#059803"
        }
    },
    formAreas: {
        margin: "0px 0 15px 20px",
        padding: "0 40px 20px",
        backgroundColor: "#fff",
    },
    progressBar: {
        margin: "0",
        padding: "1px 25px 25px 40px",
        backgroundColor: "#fff",
    },
    faIcon: {
        top: "8px",
        display: "block",
        marginRight: "0.5rem",
        color:"#ccc"
    },
    filePlaceholder: {
        lineHeight: "1",
        padding: "0",
        color: "#aaa !important",
        fontSize: "14px",
        top: "17px"
    },
    fileField: {
        marginTop: "-15px!important",
        "&>input": {
            padding: "12px 0 0"
        }
    },
    radioGroup: {
        marginTop: "30px",
    },
    checked: {
        color: dangerColor[0] + "!important"
    },
    checkRoot: {
        padding: "14px",
        "&:hover": {
            backgroundColor: "unset"
        }
    },
    checkedIcon: {
        width: "20px",
        height: "20px",
        border: "1px solid rgba(" + hexToRgb(blackColor) + ", 0.84)",
        borderRadius: "3px"
    },
    uncheckedIcon: {
        width: "0px",
        height: "0px",
        padding: "9px",
        border: "1px solid rgba(" + hexToRgb(blackColor) + ", .54)",
        borderRadius: "3px"
    },
    label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: grayColor[12],
        fontSize: "14px",
        lineHeight: "1.428571429",
        fontWeight: 400,
        display: "inline-flex",
        transition: "0.3s ease all",
        letterSpacing: "unset"
    },
    labelRoot: {
        marginLeft: "-14px"
    },
    inputLabel: {
        marginTop: "20px",
        fontFamily: '"Bungee", "Times New Roman", serif',
        fontWeight: "normal",
    },
    sections: {
        marginLeft: "20px"
    },
    imagePreview: {
        objectFit: "cover",
        width: "210px !important",
        height: "210px !important"
    },
    toolTip: {
        fontSize: "0.9rem"
    },
    p: {
        fontSize: "0.75rem",
        color: "#aaa"
    },
    removeFileContainer: {
        margin: "0 0 17px 0",

    },
    buttonSubmit: {
        borderRadius: "30px",
    },
    saveNotice: {
        color: "#AAAAAA",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: 1.428571429,
        padding: "2px 0 0 0"
    }
});

export default fieldsStyle;