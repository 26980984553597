import { createStyles } from "@material-ui/core";
import { blackColor, hexToRgb, whiteColor } from "../material-kit-pro-react";
import image from "../../img/public-hero.jpg";
import logo from "../../img/logo.png";
import equipment from "../../img/equipment.jpg";

const landingStyle = () => createStyles({
    hero: {
        padding: "110px 0px",
        position: "relative",
        fontWeight: 400,
        textDecoration: "none",
        margin: "0px",
        backgroundImage: `url('${image}')`,
        backgroundSize: 'cover',
        overflow: 'hidden',
        backgroundRepeat: 'no-repeat',
        "&:after": {
            position: "absolute",
            content: '""',
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "rgba(" + hexToRgb(blackColor) + ", 0.8)"
        }
    },
    heroContent: {
        position: "relative",
        margin: "0 auto",
        zIndex: 9999,
    },
    heroBrandContainer: {
        paddingLeft: "170px",
        backgroundPosition: "0 7px",
        backgroundImage: `url('${logo}')`,
        backgroundRepeat: 'no-repeat',
    },
    heroH1: {
        position: "relative",
        fontWeight: "normal",
        fontSize: "3.3125rem;",
        lineHeight: "1.15em",
        margin: "0px",
        fontFamily: '"Bungee", "Times New Roman", serif',
        color: whiteColor,
    },
    heroText1: {
        padding: "0 0 5px 0",
        position: "relative",
        fontSize: "1.125rem",
        lineHeight: "1.5em",
        color: whiteColor
    },
    heroText2: {
        padding: "0 0 20px 0",
        position: "relative",
        fontSize: "1.125rem",
        lineHeight: "1.5em",
        color: whiteColor
    },
    introP: {
        marginTop: "30px",
        position: "relative",
        fontSize: "1rem"
    },
    sectionHead: {
        fontFamily: '"Bungee", "Times New Roman", serif',
        fontWeight: "normal",
    },
    joinSection: {
        backgroundImage: `url('${equipment}')`,
        marginTop: "30px",
        "&:after": {
            position: "absolute",
            content: '""',
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "rgba(" + hexToRgb(blackColor) + ", 0.8)"
        }
    }
});

export default landingStyle;