import { createStyles } from "@material-ui/core";
import { blackColor, hexToRgb } from "../material-kit-pro-react";
import image from "../../img/public-hero.jpg";

const joinStyle = () => createStyles({
    hero: {
        padding: "30px 50px",
        position: "relative",
        fontWeight: 400,
        textDecoration: "none",
        margin: "0px",
        backgroundImage: `url('${image}')`,
        backgroundSize: 'cover',
        overflow: 'hidden',
        backgroundRepeat: 'no-repeat',
        "&:after": {
            position: "absolute",
            content: '""',
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "rgba(" + hexToRgb(blackColor) + ", 0.8)"
        }
    },
    header: {
        paddingTop: "130px",
        paddingBottom: "130px",
        lineHeight: "30px",
        textAlign: "center",
        position: "relative",
        color: "#fff",
        zIndex: 9999,
    },
    joinHeader: {
        fontSize: "50px",
        lineHeight: "65px",
        fontFamily: '"Bungee", "Times New Roman", serif',
        fontWeight: "normal"
    },
    textWhite: {
        color: "#fff"
    },
    subText: {
        marginBottom: "40px",
        fontSize: "16px",
        fontWeight: "normal"
    },
    logo: {
        maxWidth: "157px",
        display: "block",
        textAlign: "center",
        margin: "0 auto 35px",
    }
});

export default joinStyle;