import React from 'react';
import { Dashboard } from '../dashboard/Dashboard';
import { Landing } from '../landing/Landing';
import { Switch, Redirect } from 'react-router';
import PrivateRoute from '../../utilities/PrivateRoute';
import PublicRoute from '../../utilities/PublicRoute';

export const Content = () => {
    return (
        <Switch>
            {/* PUBLIC */}
            <PublicRoute path="/" exact component={Landing} />
            {/* DASHBOARD */}
            <PrivateRoute path="/dashboard" exact component={Dashboard} />
            {/* STORE */}
            <Redirect to="/" />
        </Switch>
    );
}